class Goal extends Layer
	ratio: 0.2
	offset: null
	selected: null
	webgl : false
	color : [217, 209, 189]
	background: [0, 117, 79]
	time: 0
	freq : 0.25
	wave_num: 6
	# focus : () ->
		# toner.radar_loop.start()
	# defocus : () ->
		# toner.radar_loop.stop()
	preload : () ->
		@font = loadFont "assets/font/fixedfixedsys.ttf"
	setup : () ->
		dir = random( Math.PI * 2)
		@diam = @size / 10
		@margin = @size * 1
		@restricted_area =
			center : createVector @size * 0.5, @size * 0.5
			diam : @margin * 0.9

		len = random (@restricted_area.diam + @diam) * 0.5, @size * 0.5
		# @pos = createVector cos( dir ) * len, sin( dir ) * len
		# @pos = @pos.add @restricted_area.center
		@pos = @restricted_area.center.copy()
		@offset = createVector 0,0
		@score = 0

		@pos.x = random 0, @size
		@pos.y = random 0, @size
		@pos = @separate_circles @pos, @diam, @restricted_area.center, @restricted_area.diam
		@pos.x = constrain @pos.x, @diam * 0.5, @size - @diam * 0.5
		@pos.y = constrain @pos.y, @diam * 0.5, @size - @diam * 0.5
		@layers.spawn.update()

		# @color = @image.color(Colors.yellow)
		# @translate_center()
	draw : (delta, time) ->
		@image.clear()
		# @image.background Colors.white


		@time += delta * @freq
		nt = @time - floor @time
		if nt < @time
			toner.trigger_radar()
		@time = nt

		@image.stroke Colors.black
		@image.strokeWeight 3
		@image.noFill()
		@image.circle @restricted_area.center.x, @restricted_area.center.y, @restricted_area.diam
		# @image.pop()


		@image.strokeWeight 3
		for i in [0...@wave_num]
			t = i / (@wave_num - 1)
			@image.noFill()

			# c = color(Colors.yellow)
			# c.setAlpha (t * 0.6 + 0.4) * 255
			# @image.stroke c
			@image.stroke Colors.yellow
			@image.circle @pos.x, @pos.y, @margin * abs(sin( @time * Math.PI * 0.5 )) * t

		@image.fill Colors.red
		@image.noStroke()
		@image.circle @pos.x, @pos.y, @margin



		@image.noStroke()
		# @image.stroke 255, 128, 128, 128
		# @image.strokeWeight 3
		@image.fill Colors.yellow
		@image.circle @pos.x, @pos.y, @diam

		@image.push()
		@image.translate(0,0,1)
		@layers.spawn.draw(delta, time, true)
		@image.pop()

		# @pos = offset_pos
		@pos = @separate_circles @pos, @diam, @restricted_area.center, @restricted_area.diam
		@pos.x = constrain @pos.x, @diam * 0.5, @size - @diam * 0.5
		@pos.y = constrain @pos.y, @diam * 0.5, @size - @diam * 0.5
		@layers.spawn.update()

	collide_pos : (p) ->
		collides = collidePointCircle p.x, p.y, @pos.x, @pos.y, @diam
		if collides
			@score += 1
			console.log "adding score #{@score}"
			# @diam = (@size / 10) + ((@size - (@size / 10)) * ((@score / @layers.game.particle_count)))
		collides
	start : () ->
		@score = 0
	pan : (p) ->
		@pixel p
		offset_pos = createVector p.x - @offset.x, p.y - @offset.y
		if @selected?
			@pos = offset_pos
			@pos = @separate_circles offset_pos, @diam, @restricted_area.center, @restricted_area.diam
			@pos.x = constrain @pos.x, 0, @size
			@pos.y = constrain @pos.y, 0, @size
			@layers.spawn.update()

	goal_at : (p) ->
		# if collidePointCircle p.x, p.y, @pos.x, @pos.y, @margin
		return true

	select : (p) ->
		p = @pixel p
		@selected = @goal_at p
		if @selected?
			@offset = p.sub @pos
	add_score : () ->
		@
	press : (p) ->
		@select p
	tap : (p) ->
		@select p
	pressup : (p) ->
		@selected = null
	panstart : (p) ->
		@select p
	panend : (p) ->
		@selected = null
